import type { LiteProps } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.types";

export const litePropsInitializer: LiteProps = {
   persistedDatabaseVersion: 1,
   currentUserID: null,
   hasSyncError: false,
   isFromLite: false,
   taskListSortBy: "checklistDueDate",
   taskListSortDirection: "desc",
};
