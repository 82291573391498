import { litePropsInitializer } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.initializer";
import { litePropsSchema } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.schema";
import { PropertiesResource } from "src/app/lite/local-db/resources/properties/properties.resource";
import { local } from "src/app/lite/local-db/resources/properties/properties.resource.utils";

export const litePropsResource = new PropertiesResource({
   name: "liteProps",
   initialize: local({
      init: litePropsInitializer,
      validation: litePropsSchema,
   }),
   database: "litePersist",
});
