import { z } from "zod";

export const litePropsSchema = z.object({
   persistedDatabaseVersion: z.number(),
   currentUserID: z.number().nullable(),
   taskListSortBy: z.enum([
      "locationName",
      "priorityDisplayName",
      "statusDisplayName",
      "checklistDueDate",
      "assignedToDisplayName",
      "checklistLastEdited",
      "assetName",
      "checklistCreatedDate",
   ]),
   taskListSortDirection: z.enum(["asc", "desc"]),
   hasSyncError: z.boolean(),
   isFromLite: z.boolean(),
});
