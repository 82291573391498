import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { litePropsInitializer } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.initializer";
import { litePropsResource } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.resource";
import type { LiteProps } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.types";
import { PropertiesStorageService } from "src/app/lite/local-db/resources/properties/properties.storage.service";
import type { ExtractInitializer } from "src/app/lite/local-db/resources/properties/properties.storage.service.types";
import { defaultValue } from "src/app/lite/local-db/resources/properties/properties.storage.service.utils";

@Injectable({ providedIn: "root" })
export class LitePropsStorageService extends PropertiesStorageService<
   ExtractInitializer<typeof litePropsResource>
> {
   public constructor() {
      super(litePropsResource);
   }

   public getCurrentUserID$(): Observable<number | null> {
      return this.read$((transaction) =>
         this.get$("currentUserID", transaction).pipe(
            defaultValue(litePropsInitializer.currentUserID),
         ),
      );
   }

   public async setCurrentUserID(taskID: number): Promise<void> {
      return this.write(async (transaction) =>
         this.set("currentUserID", taskID, transaction),
      );
   }

   public async clearCurrentUserID(): Promise<void> {
      return this.write(async (transaction) => this.clear("currentUserID", transaction));
   }

   public getHasSyncError$(): Observable<boolean> {
      return this.read$((transaction) => this.get$("hasSyncError", transaction)).pipe(
         defaultValue(litePropsInitializer.hasSyncError),
      );
   }

   public async setHasSyncError(hasSyncError: boolean): Promise<void> {
      return this.write(async (transaction) =>
         this.set("hasSyncError", hasSyncError, transaction),
      );
   }

   public async setIsFromLite(isFromLite: boolean): Promise<void> {
      return this.write(async (transaction) =>
         this.set("isFromLite", isFromLite, transaction),
      );
   }

   public getIsFromLite$(): Observable<boolean> {
      return this.read$((transaction) =>
         this.get$("isFromLite", transaction).pipe(
            defaultValue(litePropsInitializer.isFromLite),
         ),
      );
   }

   public getTaskListSortBy$(): Observable<LiteProps["taskListSortBy"]> {
      return this.read$((transaction) =>
         this.get$("taskListSortBy", transaction).pipe(
            defaultValue(litePropsInitializer.taskListSortBy),
         ),
      );
   }

   public getTaskListSortDirection$(): Observable<LiteProps["taskListSortDirection"]> {
      return this.read$((transaction) =>
         this.get$("taskListSortDirection", transaction).pipe(
            defaultValue(litePropsInitializer.taskListSortDirection),
         ),
      );
   }

   public async setTaskListSortBind(sortBy: LiteProps["taskListSortBy"]): Promise<void> {
      return this.write(async (transaction) =>
         this.set("taskListSortBy", sortBy, transaction),
      );
   }

   public async setTaskListSortDirection(
      sortDirection: LiteProps["taskListSortDirection"],
   ): Promise<void> {
      return this.write(async (transaction) =>
         this.set("taskListSortDirection", sortDirection, transaction),
      );
   }

   public async resetLiteProps(): Promise<void> {
      const litePropsDefaultEntries = Object.entries(litePropsInitializer) as Array<
         [keyof LiteProps, any]
      >;

      await this.write(async (transaction) =>
         Promise.all(
            litePropsDefaultEntries.map(async ([key, defaultVal]) =>
               this.set(key, defaultVal, transaction),
            ),
         ),
      );
   }
}
